import http from '../../utils/axios/admin';
let state = {
    Setting: {
        data: {},
        errors: {}
    },
}


let actions = {
    fetchSetting({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get(' https://pode.ly/api/admin/settings', { params: filter }).then((response) => {
                    commit('setSetting', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    updateSetting({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/settings', data).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },


}


let mutations = {

    setSetting(state, data) {
        state.Setting.data = data.data;
    },

}

export default {
    state,
    actions,
    mutations,
}