import Vue from 'vue';
import Vuex from 'vuex';
import Auth from './modules/auth';
import users from './modules/users';
import categories from './modules/categories';
import subcategories from './modules/subcategories';
import products from './modules/products';
import colors from './modules/colors';
import attachments from './modules/attachments';
import sale from './modules/sale';
import status from './modules/status';
import clients from './modules/clients';
import discount from './modules/discount';
import countries from './modules/countries';
import cities from './modules/cities';
import settings from './modules/settings';
import advertisements from './modules/advertisements';
import deliveryCompany from './modules/deliveryCompany';
import dashboard from './modules/dashboard';


Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        admin: {
            namespaced: true,
            modules: {
                auth: Auth,
                users: users,
                categories: categories,
                subcategories: subcategories,
                products: products,
                colors: colors,
                attachments: attachments,
                sale: sale,
                status: status,
                clients: clients,
                discount: discount,
                countries: countries,
                cities: cities,
                settings: settings,
                advertisements: advertisements,
                deliveryCompany: deliveryCompany,
                dashboard: dashboard
            }
        }
    }
})

export default store;