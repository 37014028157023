import http from '../../utils/axios/admin';
let state = {
    SubCategoriesList: {
        data: [],
        links: {},
        meta: {}
    },
    SubCategory: {
        data: {},
        errors: {}
    },
}


let actions = {
    fetchSubCategoriesList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get(' https://pode.ly/api/admin/categories/' + filter.category_uuid + '/sub-categories', { params: filter }).then((response) => {
                    commit('setSubCategoriesList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    fetchSubCategory({ commit }, {
        sub_category_uuid: sub_category_uuid,
        category_uuid: category_uuid
    }) {
        return new Promise((resolve, reject) => {
            http.get(' https://pode.ly/api/admin/categories/' + category_uuid + '/sub-categories/' + sub_category_uuid).then((response) => {
                    commit('setSubCategory', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    storeSubCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/categories/' + data.get('uuid') + '/sub-categories', data).then((response) => {
                    commit('setSubCategory', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setSubCategoryErrors', error.response.data);
                    }
                    reject();
                });
        });
    },
    updateSubCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/categories/' + data.get("uuid") + '/sub-categories/' + data.get('sub_category_uuid'), data).then((response) => {
                    commit('setSubCategory', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setSubCategoryErrors', error.response.data);
                    }
                    reject();
                });
        });
    },

    deleteSubCategory({ commit }, { category_uuid, sub_category_uuid }) {
        return new Promise((resolve, reject) => {
            http.delete(' https://pode.ly/api/admin/categories/' + category_uuid + '/sub-categories/' + sub_category_uuid).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },


}


let mutations = {
    setSubCategoriesList(state, data) {
        state.SubCategoriesList = data;
    },
    setSubCategory(state, data) {
        state.SubCategory.data = data.data;
    },
    setSubCategoryErrors(state, errors) {
        state.SubCategory.errors = errors.errors;
    },
    cleanSubCategoriesErrors(state, errors) {
        state.SubCategory.errors = {};
    },

}

export default {
    state,
    actions,
    mutations,
}