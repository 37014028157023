import http from '../../utils/axios/admin';
let state = {
    ProductAttachmentsList: {
        data: [],
        links: {},
        meta: {}
    },

}


let actions = {
    fetchProductAttachmentsList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/client/product-attachments/' + filter.uuid, { params: filter }).then((response) => {
                    commit('setProductAttachmentsList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    fetchProductAttachment({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/client/product-attachment/' + id).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    deleteAttachment({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.delete('https://pode.ly/api/admin/product-attachment/' + id).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

    updateProductAttachment({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post('https://pode.ly/api/admin/update-product-attachment/' + data.get('id'), data).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

}


let mutations = {
    setProductAttachmentsList(state, data) {
        state.ProductAttachmentsList = data;
    },
}

export default {
    state,
    actions,
    mutations,
}