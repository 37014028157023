import http from '../../utils/axios/admin';
let state = {
    AdvertisementsList: {
        data: [],
        links: {},
        meta: {}
    },
    Advertisement: {
        data: {},
        errors: {}
    },

}


let actions = {
    fetchAdvertisementsList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/pode-advertisements', { params: filter }).then((response) => {
                    commit('setAdvertisesList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

    fetchAdvertise({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.get(' https://pode.ly/api/admin/pode-advertisements/' + id).then((response) => {
                    commit('setAdvertise', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    storeAdvertise({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/pode-advertisements', data).then((response) => {
                    commit('setAdvertise', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setAdvertiseErrors', error.response.data);
                    }
                    reject();
                });
        });
    },
    updateAdvertise({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/pode-advertisements/' + data.get("id"), data).then((response) => {
                    commit('setAdvertise', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setAdvertiseErrors', error.response.data);
                    }
                    reject();
                });
        });
    },

    deleteAdvertise({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.delete(' https://pode.ly/api/admin/pode-advertisements/' + id).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

}


let mutations = {
    setAdvertisesList(state, data) {
        state.AdvertisementsList = data;
    },
    setAdvertise(state, data) {
        state.Advertisement.data = data.data;
    },
    setAdvertiseErrors(state, errors) {
        state.Advertisement.errors = errors.errors;
    },
    cleanAdvertiseErrors(state, errors) {
        state.Advertisement.errors = {};
    },
}

export default {
    state,
    actions,
    mutations,
}