import http from '../../utils/axios/admin';
let state = {
    CitiesList: {
        data: [],
        links: {},
        meta: {}
    },
    City: {
        data: {},
        errors: {}
    },

}


let actions = {
    fetchCitesList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/cities', { params: filter }).then((response) => {
                    commit('setCitiesList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

    fetchCity({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.get(' https://pode.ly/api/admin/cities/' + id).then((response) => {
                    commit('setCity', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    storeCity({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/cities', data).then((response) => {
                    commit('setCity', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setCityErrors', error.response.data);
                    }
                    reject();
                });
        });
    },
    updateCity({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/cities/' + data.get("id"), data).then((response) => {
                    commit('setCity', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setCityErrors', error.response.data);
                    }
                    reject();
                });
        });
    },

    deleteCity({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.delete(' https://pode.ly/api/admin/cities/' + id).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

}


let mutations = {
    setCitiesList(state, data) {
        state.CitiesList = data;
    },
    setCity(state, data) {
        state.City.data = data.data;
    },
    setCityErrors(state, errors) {
        state.City.errors = errors.errors;
    },
    cleanCityErrors(state, errors) {
        state.City.errors = {};
    },
}

export default {
    state,
    actions,
    mutations,
}