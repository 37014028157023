import http from '../../utils/axios/admin';
const state = {
    CitiesNodeList: [],
    ServicePackagesList: [],

};

const actions = {
    getCitiesNodeList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/client/get/cities', filter).then((response) => {
                    commit('setCitiesList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getServicePackagesList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/client/get/service/packages', filter).then((response) => {
                    commit('setServicePackagesList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

};
const mutations = {
    setCitiesList(state, data) {
        state.CitiesNodeList = data;
    },
    setServicePackagesList(state, data) {
        state.ServicePackagesList = data;
    },
};

export default {
    state,
    actions,
    mutations
}