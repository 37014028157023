import http from '../../utils/axios/admin';
let state = {
    authUser: {},
    isLogin: false,

    UsersList: {
        data: [],
        links: {},
        meta: {}
    },
    User: {
        data: {},
        error: {},
    },
    Roles: [],
    Permissions: [],
}

const getters = {
    getIsLogin(state) {
        return state.isLogin;
    },
    canManageService: () => (service) => {
        var user_role = state.authUser.role[0];
        const user_allowed_services = state.authUser.permissions;
        if (user_role == 'admin') {
            return true;
        } else {
            return user_allowed_services.some((element) => {
                return service == element.name;
            })
        }
    },
};

let actions = {
    fetchAuthUser({ commit }) {
        return new Promise((resolve, reject) => {
            http.get(' https://pode.ly/api/admin/auth-user').then((response) => {
                    commit('setAuthUser', response.data);
                    commit('changeLoginStatus', true);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        commit('changeLoginStatus', false);
                    }
                    reject();
                });
        });
    },
    fetchUsersList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/users', { params: filter }).then((response) => {
                commit('setUserList', response.data);
                resolve(response);
            }).catch((errors) => {
                reject(errors);
            })
        })
    },

    fetchRoles({ commit }) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/user/roles').then((response) => {
                commit('setRoles', response.data);
                resolve(response);
            }).catch((errors) => {
                reject(errors);
            })
        })
    },
    fetchPermissions({ commit }) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/user/permissions').then((response) => {
                commit('setPermissions', response.data);
                resolve(response);
            }).catch((errors) => {
                reject(errors);
            })
        })
    },

    deleteUser({ commit }, uuid) {
        return http.delete('https://pode.ly/api/admin/users/' + uuid);
    },



    CreateUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post('https://pode.ly/api/admin/users', data).then((response) => {
                commit('setUser', response.data);
                resolve(response);
            }).catch((error) => {
                if (error.response && error.response.status === 422) {
                    commit('setUserError', error.response.data);
                }
                reject(error.response);
            })
        });

    },


    fetchUser({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/users/' + id).then((response) => {
                commit('setUser', response.data);
                resolve(response);
            }).catch((errors) => {
                reject(errors);
            })
        })
    },



    updateUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post('https://pode.ly/api/admin/users/' + data.get('id'), data).then((response) => {
                commit('setUser', response.data);
                resolve(response);
            }).catch((error) => {
                if (error.response && error.response.status === 422) {
                    commit('setUserError', error.response.data);
                }
                reject(error.response);
            })
        });
    },



}


let mutations = {
    setAuthUser(state, data) {
        state.authUser = data.data;
    },
    changeLoginStatus(state, value) {
        state.isLogin = value;
    },

    setUserInfo(state, user) {
        state.AuthUser = user;
    },
    setRoles(state, data) {
        state.Roles = data;
    },
    setPermissions(state, data) {
        state.Permissions = data;
    },

    setUserList(state, data) {
        state.UsersList = data;
    },
    setUser(state, data) {
        state.User.data = data;
    },
    setUserError(state, error) {

        state.User.error = error.errors;
    },
    cleanUserErrors(state, error) {
        state.User.error = {};
    },
}

export default {
    state,
    actions,
    mutations,
    getters
}