import http from '../../utils/axios/admin';
const state = {
    DashboardStatistics: [],
};

const actions = {
    getDashboardStatisticsList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/dashboard-statistics', filter).then((response) => {
                    commit('setDashboardStatistics', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};
const mutations = {
    setDashboardStatistics(state, data) {
        state.DashboardStatistics = data;
    },

};

export default {
    state,
    actions,
    mutations
}