import http from '../../utils/axios/admin';
let state = {
    ClientsList: {
        data: [],
        links: {},
        meta: {}
    },

    Client: {
        data: {},
        errors: {}
    },
}


let actions = {
    fetchClientsList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/clients', { params: filter }).then((response) => {
                    commit('setClientsList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    fetchClient({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/clients/' + uuid).then((response) => {
                    commit('setClient', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

    deleteClient({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            http.delete('https://pode.ly/api/admin/clients/' + uuid).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },


}


let mutations = {
    setClientsList(state, data) {
        state.ClientsList = data;
    },
    setClient(state, data) {
        state.Client.data = data.data;
    },
    setClientErrors(state, errors) {
        state.Client.errors = errors.errors;
    },
    cleanClientsErrors(state, errors) {
        state.Client.errors = {};
    },

}

export default {
    state,
    actions,
    mutations,
}