import http from '../../utils/axios/admin';
let state = {
    StatusList: {
        data: [],
        links: {},
        meta: {}
    },
    Status: {
        data: {},
        errors: {}
    },
}


let actions = {
    fetchStatusList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get(' https://pode.ly/api/admin/status', { params: filter }).then((response) => {
                    commit('setStatusList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    updateStatus({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/status/' + data.get("id"), data).then((response) => {
                    commit('setStatus', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setStatusErrors', error.response.data);
                    }
                    reject();
                });
        });
    },

    fetchStatus({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.get(' https://pode.ly/api/admin/status/' + id).then((response) => {
                    commit('setStatus', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },


}


let mutations = {
    setStatusList(state, data) {
        state.StatusList = data;
    },
    setStatus(state, data) {
        state.Status.data = data.data;
    },
    setStatusErrors(state, errors) {
        state.Status.errors = errors.errors;
    },
    cleanStatusErrors(state, errors) {
        state.Status.errors = {};
    },


}

export default {
    state,
    actions,
    mutations,
}