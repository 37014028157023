import http from '../../utils/axios/admin';
let state = {
    CategoriesList: {
        data: [],
        links: {},
        meta: {}
    },
    Category: {
        data: {},
        errors: {}
    },
    ProductCategoriesList: {
        data: [],
        links: {},
        meta: {}
    },
    ProductCategory: {
        data: {},
        errors: {}
    },
}


let actions = {
    fetchCategoriesList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get(' https://pode.ly/api/admin/categories', { params: filter }).then((response) => {
                    commit('setCategoriesList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    fetchCategory({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            http.get(' https://pode.ly/api/admin/categories/' + uuid).then((response) => {
                    commit('setCategory', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    storeCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/categories', data).then((response) => {
                    commit('setCategory', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setCategoryErrors', error.response.data);
                    }
                    reject();
                });
        });
    },
    updateCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/categories/' + data.get("uuid"), data).then((response) => {
                    commit('setCategory', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setCategoryErrors', error.response.data);
                    }
                    reject();
                });
        });
    },

    deleteCategory({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            http.delete(' https://pode.ly/api/admin/categories/' + uuid).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },




    fetchProductCategoriesList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/client/product/' + filter.product_uuid + '/categories', { params: filter }).then((response) => {
                    commit('setProductCategoriesList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

    fetchProductCategory({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/client/product/' + filter.product_uuid + '/category/' + filter.id).then((response) => {
                    commit('setProductCategory', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

    storeProductCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post('https://pode.ly/api/admin/product/' + data.get('product_uuid') + '/category', data).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setProductCategoryErrors', error.response.data);
                    }
                    reject();
                });
        });
    },


    updateProductCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post('https://pode.ly/api/admin/product/' + data.get('product_uuid') + '/category/' + data.get('id'), data).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setProductCategoryErrors', error.response.data);
                    }
                    reject();
                });
        });
    },

    deleteProductCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.delete('https://pode.ly/api/admin/product/' + data.product_uuid + '/category/' + data.id, data).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },



    storeCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/categories', data).then((response) => {
                    commit('setCategory', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setCategoryErrors', error.response.data);
                    }
                    reject();
                });
        });
    },
    updateCategory({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/categories/' + data.get("uuid"), data).then((response) => {
                    commit('setCategory', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setCategoryErrors', error.response.data);
                    }
                    reject();
                });
        });
    },

    deleteCategory({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            http.delete(' https://pode.ly/api/admin/categories/' + uuid).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },



}


let mutations = {
    setCategoriesList(state, data) {
        state.CategoriesList = data;
    },
    setCategory(state, data) {
        state.Category.data = data.data;
    },
    setCategoryErrors(state, errors) {
        state.Category.errors = errors.errors;
    },
    cleanCategoriesErrors(state, errors) {
        state.Category.errors = {};
    },


    setProductCategoriesList(state, data) {
        state.ProductCategoriesList = data;
    },
    setProductCategory(state, data) {
        state.ProductCategory.data = data;
    },
    setProductCategoryErrors(state, errors) {
        state.ProductCategory.errors = errors.errors;
    },
    cleanProductCategoryErrors(state, errors) {
        state.ProductCategory.errors = {};
    },

}

export default {
    state,
    actions,
    mutations,
}