import http from '../../utils/axios/admin';
let state = {
    DiscountCodeList: {
        data: [],
        links: {},
        meta: {}
    },
    DiscountCode: {
        data: {},
        errors: {}
    },
}


let actions = {
    fetchDiscountCodeList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/code-discount', { params: filter }).then((response) => {
                    commit('setDiscountCodeList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    fetchDiscountCode({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.get(' https://pode.ly/api/admin/code-discount/' + id).then((response) => {
                    commit('setDiscountCode', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    storeDiscountCode({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/code-discount', data).then((response) => {
                    commit('setDiscountCode', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setDiscountCodeErrors', error.response.data);
                    }
                    reject();
                });
        });
    },
    updateDiscountCode({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/code-discount/' + data.get('id'), data).then((response) => {
                    commit('setDiscountCode', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setDiscountCodeErrors', error.response.data);
                    }
                    reject();
                });
        });
    },

    deleteDiscountCode({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.delete(' https://pode.ly/api/admin/code-discount/' + id).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },




}


let mutations = {
    setDiscountCodeList(state, data) {
        state.DiscountCodeList = data;
    },
    setDiscountCode(state, data) {
        state.DiscountCode.data = data.data;
    },
    setDiscountCodeErrors(state, errors) {
        state.DiscountCode.errors = errors.errors;
    },
    cleanDiscountCodeErrors(state, errors) {
        state.DiscountCode.errors = {};
    },

}

export default {
    state,
    actions,
    mutations,
}