import http from '../../utils/axios/admin';
let state = {
    ProductsList: {
        data: [],
        links: {},
        meta: {}
    },

    Product: {
        data: {},
        errors: {}
    },
}


let actions = {
    fetchProductsList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/products', { params: filter }).then((response) => {
                    commit('setProductsList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    fetchProduct({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/products/' + uuid).then((response) => {
                    commit('setProduct', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    storeProduct({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post('https://pode.ly/api/admin/products', data).then((response) => {
                    commit('setProduct', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setProductErrors', error.response.data);
                    }
                    reject();
                });
        });
    },
    updateProduct({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post('https://pode.ly/api/admin/products/' + data.get("uuid"), data).then((response) => {
                    commit('setProduct', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setProductErrors', error.response.data);
                    }
                    reject();
                });
        });
    },

    deleteProduct({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            http.delete('https://pode.ly/api/admin/products/' + uuid).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },


}


let mutations = {
    setProductsList(state, data) {
        state.ProductsList = data;
    },
    setProduct(state, data) {
        state.Product.data = data.data;
    },
    setProductErrors(state, errors) {
        state.Product.errors = errors.errors;
    },
    cleanProductsErrors(state, errors) {
        state.Product.errors = {};
    },

}

export default {
    state,
    actions,
    mutations,
}