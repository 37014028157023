import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'

Vue.use(Router);
const routes = [{
        path: '/',
        component: () =>
            import ('../pages/Auth/Login.vue'),
        name: 'admin.login',
        meta: {
            guest: true,
        }

    },
    {
        path: '/home',
        component: () =>
            import ('../pages/Dashboard/HomeIndex.vue'),
        children: [{
                path: '/home',
                component: () =>
                    import ('../pages/Dashboard/Dashboard.vue'),
                name: 'admin.dashboard.home',
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/categories-list',
                component: () =>
                    import ('../pages/Dashboard/CategoriesManagement/CategoriesList.vue'),
                name: 'admin.dashboard.categories.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_categories');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/category/details/:uuid',
                component: () =>
                    import ('../pages/Dashboard/CategoriesManagement/CategoryView.vue'),
                name: 'admin.dashboard.category.view',
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/category/create',
                component: () =>
                    import ('../pages/Dashboard/CategoriesManagement/CategoryForm.vue'),
                name: 'admin.dashboard.category.create',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_categories') && store.getters['admin/canManageService']('can_add');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/category/edit/:uuid',
                component: () =>
                    import ('../pages/Dashboard/CategoriesManagement/CategoryForm.vue'),
                name: 'admin.dashboard.category.edit',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_categories') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },













            {
                path: '/sub-categories-list/:category_uuid',
                component: () =>
                    import ('../pages/Dashboard/CategoriesManagement/SubCategories/SubCategoriesList.vue'),
                name: 'admin.dashboard.subcategories.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_sub_categories');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/category/:category_uuid/sub-category/:sub_category_uuid',
                component: () =>
                    import ('../pages/Dashboard/CategoriesManagement/SubCategories/SubCategoryView.vue'),
                name: 'admin.dashboard.subcategories.view',
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/sub-category/:category_uuid/create',
                component: () =>
                    import ('../pages/Dashboard/CategoriesManagement/SubCategories/SubCategoryForm.vue'),
                name: 'admin.dashboard.subcategories.create',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_sub_categories') && store.getters['admin/canManageService']('can_add');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/sub-category/:category_uuid/edit/:sub_category_uuid',
                component: () =>
                    import ('../pages/Dashboard/CategoriesManagement/SubCategories/SubCategoryForm.vue'),
                name: 'admin.dashboard.subcategories.edit',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_sub_categories') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },








            {
                path: '/products-list',
                component: () =>
                    import ('../pages/Dashboard/ProductsManagement/ProductsList.vue'),
                name: 'admin.dashboard.products.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_products');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/product/details/:uuid',
                component: () =>
                    import ('../pages/Dashboard/ProductsManagement/ProductView.vue'),
                name: 'admin.dashboard.product.view',
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/product/create',
                component: () =>
                    import ('../pages/Dashboard/ProductsManagement/ProductForm.vue'),
                name: 'admin.dashboard.product.create',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_products') && store.getters['admin/canManageService']('can_add');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/product/edit/:uuid',
                component: () =>
                    import ('../pages/Dashboard/ProductsManagement/ProductForm.vue'),
                name: 'admin.dashboard.product.edit',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_products') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/product/attachment/:uuid',
                component: () =>
                    import ('../pages/Dashboard/ProductsManagement/Attachments/ProductAttachments.vue'),
                name: 'admin.dashboard.product.attachments.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_products');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/product/attachment/update/:id',
                component: () =>
                    import ('../pages/Dashboard/ProductsManagement/Attachments/ProductAttachmentForm.vue'),
                name: 'admin.dashboard.product.attachment.update',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_products') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },











            {
                path: '/product/category/create/:product_uuid',
                component: () =>
                    import ('../pages/Dashboard/ProductsManagement/Categories/ProductCategoryForm.vue'),
                name: 'admin.dashboard.product.category.create',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_categories') && store.getters['admin/canManageService']('can_add');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/product/:product_uuid/category/edit/:id',
                component: () =>
                    import ('../pages/Dashboard/ProductsManagement/Categories/ProductCategoryForm.vue'),
                name: 'admin.dashboard.product.category.edit',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_categories') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/product/:product_uuid/categories',
                component: () =>
                    import ('../pages/Dashboard/ProductsManagement/Categories/ProductCategoriesList.vue'),
                name: 'admin.dashboard.product.categories.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_categories');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/product/:product_uuid/category/:id/view',
                component: () =>
                    import ('../pages/Dashboard/ProductsManagement/Categories/ProductCategoryView.vue'),
                name: 'admin.dashboard.product.category.view',
                meta: {
                    requiresAuth: true,
                },
            },










            {
                path: '/sales-list',
                component: () =>
                    import ('../pages/Dashboard/SalesManagement/SalesList.vue'),
                name: 'admin.dashboard.sales.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_orders');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },

            {
                path: '/sales/view/:uuid',
                component: () =>
                    import ('../pages/Dashboard/SalesManagement/SalesView.vue'),
                name: 'admin.dashboard.sales.view',
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/sales/update/status/:uuid',
                component: () =>
                    import ('../pages/Dashboard/SalesManagement/SalesForm.vue'),
                name: 'admin.dashboard.sales.update.status',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_orders') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },





            {
                path: '/clients-list',
                component: () =>
                    import ('../pages/Dashboard/ClientsManagement/ClientsList.vue'),
                name: 'admin.dashboard.clients.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_cities');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },

            {
                path: '/client/view/:uuid',
                component: () =>
                    import ('../pages/Dashboard/ClientsManagement/ClientView.vue'),
                name: 'admin.dashboard.client.view',
                meta: {
                    requiresAuth: true,
                },
            },

            /**
             * 
             */

            {
                path: '/code-discount-list',
                component: () =>
                    import ('../pages/Dashboard/CodeDiscountManagement/CodeDiscountList.vue'),
                name: 'admin.dashboard.discount.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_discount_code');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/code-discount/create',
                component: () =>
                    import ('../pages/Dashboard/CodeDiscountManagement/CodeDiscountForm.vue'),
                name: 'admin.dashboard.discount.create',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_discount_code') && store.getters['admin/canManageService']('can_add');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/code-discount/edit/:id',
                component: () =>
                    import ('../pages/Dashboard/CodeDiscountManagement/CodeDiscountForm.vue'),
                name: 'admin.dashboard.discount.edit',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_discount_code') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            /**
             * 
             */
            {
                path: '/colors-list',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/ColorsManagement/ColorsList.vue'),
                name: 'admin.dashboard.colors.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_colors');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/color/create',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/ColorsManagement/ColorForm.vue'),
                name: 'admin.dashboard.colors.create',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_colors') && store.getters['admin/canManageService']('can_add');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/color/edit/:id',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/ColorsManagement/ColorForm.vue'),
                name: 'admin.dashboard.colors.edit',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_colors') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            /**
             * 
             */
            {
                path: '/colors-list',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/ColorsManagement/ColorsList.vue'),
                name: 'admin.dashboard.colors.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_colors');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/color/create',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/ColorsManagement/ColorForm.vue'),
                name: 'admin.dashboard.colors.create',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_colors') && store.getters['admin/canManageService']('can_add');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/color/edit/:id',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/ColorsManagement/ColorForm.vue'),
                name: 'admin.dashboard.colors.edit',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_colors') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            /**
             * 
             */
            {
                path: '/countries-list',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/CountriesManagement/CountriesList.vue'),
                name: 'admin.dashboard.countries.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_countries');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/country/create',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/CountriesManagement/CountryForm.vue'),
                name: 'admin.dashboard.countries.create',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_countries') && store.getters['admin/canManageService']('can_add');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/country/edit/:id',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/CountriesManagement/CountryForm.vue'),
                name: 'admin.dashboard.countries.edit',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_countries') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            /**
             * 
             */
            {
                path: '/cities-list',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/CitiesManagement/CitiesList.vue'),
                name: 'admin.dashboard.cities.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_cities');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/city/create',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/CitiesManagement/CityForm.vue'),
                name: 'admin.dashboard.cities.create',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_cities') && store.getters['admin/canManageService']('can_add');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/city/edit/:id',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/CitiesManagement/CityForm.vue'),
                name: 'admin.dashboard.cities.edit',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_cities') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },

            /**
             * 
             */
            {
                path: '/status-list',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/StatusManagement/StatusList.vue'),
                name: 'admin.dashboard.status.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_status');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/status/edit/:id',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/StatusManagement/StatusForm.vue'),
                name: 'admin.dashboard.status.edit',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_status') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },

            /**
             * 
             */
            {
                path: '/users/list',
                component: () =>
                    import ('../pages/Dashboard/UsersManagement/UsersList.vue'),
                name: 'admin.dashboard.users.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_users');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/user/create',
                component: () =>
                    import ('../pages/Dashboard/UsersManagement/UserForm.vue'),
                name: 'admin.dashboard.user.create',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_users') && store.getters['admin/canManageService']('can_add');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }

            },
            {
                path: '/user/edit/:id',
                component: () =>
                    import ('../pages/Dashboard/UsersManagement/UserForm.vue'),
                name: 'admin.dashboard.user.edit',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_users') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }

            },
            {
                path: '/user/details/:id',
                component: () =>
                    import ('../pages/Dashboard/UsersManagement/UserDetails.vue'),
                name: 'admin.dashboard.user.details',
                meta: {
                    requiresAuth: true,
                },

            },

            /**
             * 
             */

            {
                path: '/settings',
                component: () =>
                    import ('../pages/Dashboard/ApplicationsManagement/SettingsManagement/SettingsForm.vue'),
                name: 'admin.dashboard.settings',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_settings') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            /**
             * 
             */


            {
                path: '/advertisement/list',
                component: () =>
                    import ('../pages/Dashboard/AdvertisementManagement/AdvertisementList.vue'),
                name: 'admin.dashboard.advertisement.list',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_advertisement');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }
            },
            {
                path: '/advertisement/create',
                component: () =>
                    import ('../pages/Dashboard/AdvertisementManagement/AdvertisementForm.vue'),
                name: 'admin.dashboard.advertisement.create',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_advertisement') && store.getters['admin/canManageService']('can_add');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }

            },
            {
                path: '/advertisement/edit/:id',
                component: () =>
                    import ('../pages/Dashboard/AdvertisementManagement/AdvertisementForm.vue'),
                name: 'admin.dashboard.advertisement.edit',
                meta: {
                    requiresAuth: true,
                },
                beforeEnter(to, from, next) {
                    store.dispatch('admin/fetchAuthUser').then((response) => {
                        let res = store.getters['admin/canManageService']('manage_advertisement') && store.getters['admin/canManageService']('can_edit');
                        (res) ? next(true): next(false);
                    }).catch((error) => {

                    })
                }

            },
        ],
    },
    // {
    //     path: '*',
    //     component: () =>
    //         import ('../pages/Helpers/Page404.vue'),

    // }

];









const router = new Router({
    mode: 'hash',
    routes
});


router.beforeEach((to, from, next) => {
    store.commit('admin/PleaseStartLoading');
    next();
})
router.afterEach((to, from) => {
    store.commit('admin/PleaseStopLoading');
})


/**
 * # Auth Middleware.
 * ----------------------
 * Redirect any route that requires auth to login view.
 */
router.beforeEach((to, from, next) => {
    let userToken = localStorage.getItem('admin/user-token');
    // console.log(to);
    if (to.matched.some(record => record.meta.requiresAuth)) {
        let name = 'admin.login';

        if (userToken === null) {
            next({
                name: name,
                params: { nextUrl: to.fullPath }
            });
        } else {
            next();
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (userToken == null) {
            next();
        } else {
            next({ name: 'admin.dashboard.home' });
        }
    } else {
        next();
    }
});



export default router;