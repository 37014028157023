import http from '../../utils/axios/admin';
const state = {
    token: localStorage.getItem('admin/user-token') || '',
    errors: {},
    Loading: {
        isLoading: true,
        fullPage: true
    }
};
const getters = {
    authToken(state) {
        return state.token;
    },
};

const actions = {
    /**
     * Authenticate Request.
     */
    authRequest({ commit }, { email, password }) {
        return new Promise((resolve, reject) => {

            http.post('https://pode.ly/api/admin/login', {
                    email,
                    password,
                })
                .then(response => {
                    commit('authSuccess', response.data.data.access_token);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('authError', error.response.data);
                    }

                    reject(error);
                });
        });
    },


    /**
     * Auth logout.
     */
    authLogout({ commit }) {

        return new Promise((resolve, reject) => {

            http.post('https://pode.ly/api/admin/logout').then((response) => {
                    commit('authLogout');
                    resolve(response);
                })
                .catch((error) => {
                    commit('authLogout');
                    reject(error);
                });
        });
    },
};
const mutations = {
    authSuccess(state, token) {
        localStorage.setItem('admin/user-token', token);
        state.token = token;
        state.errors = {};
    },
    authLogout(state) {
        localStorage.removeItem('admin/user-token');
        state.token = '';
    },
    authError(state, errors) {
        localStorage.removeItem('admin/user-token');
        state.errors = errors.errors;
        state.token = '';
    },
    authCleanErrors(state) {
        state.errors = {};
    },
    PleaseStopLoading(state) {
        state.Loading.isLoading = false;
    },
    PleaseStartLoading(state) {
        state.Loading.isLoading = true;
    },
};

export default {
    state,
    actions,
    getters,
    mutations
}