import axios from 'axios';
import store from '../../store';
import router from '../../router';

// Set config defaults when creating the instance
const admin = axios.create();



// request interceptor.
admin.interceptors.request.use((request) => {
    const token = store.getters['admin/authToken'];
    // const locale = Vue.i18n.locale();

    if (token) {
        request.headers.common['Authorization'] = `Bearer ${token}`;
    }

    // if (locale) {
    request.headers.common['Accept-Language'] = 'en';

    // }
    return request;
});

// response interceptor
admin.interceptors.response.use(response => response, error => {
    const { status } = error.response;
    if (status >= 500) {

        alert(error.response);
    }

    if (status == 401) {

        store.commit('admin/authLogout');

        router.push({ name: 'admin.login' });
        // });
    }

    return Promise.reject(error)
});


export default admin;