<template>
  <div id="app">
    <loading :active.sync="isLoading" 
        :can-cancel="true" 
        :is-full-page="fullPage"
        color="#000"
        background-color='#fff'
        ></loading>
    <router-view></router-view>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import {mapState} from 'vuex'; 
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'App',
  data() 
    {
      return {
          fullPage: true
      }
    },
    computed:{
      ...mapState({
	   	isLoading: state => state.admin.auth.Loading.isLoading, 
		})
    },
  components: {
    Loading
  },
  mounted(){
 

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
