import http from '../../utils/axios/admin';
let state = {
    SalesList: {
        data: [],
        links: {},
        meta: {}
    },
    Sale: {
        data: {},
        errors: {}
    },

}


let actions = {
    fetchSalesList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/sales', { params: filter }).then((response) => {
                    commit('setSalesList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    fetchSale({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/sales/' + uuid).then((response) => {
                    commit('setSale', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

    deleteSale({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            http.delete('https://pode.ly/api/admin/sales/' + uuid).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },


    updateSaleStatus({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post('https://pode.ly/api/admin/sales-update-status/' + data.get('uuid'), data).then((response) => {
                    commit('setSale', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setSaleErrors', error.response.data);
                    }
                    reject();
                });
        });
    },




}


let mutations = {
    setSalesList(state, data) {
        state.SalesList = data;
    },
    setSale(state, data) {
        state.Sale.data = data.data;
    },
    setSaleErrors(state, errors) {
        state.Sale.errors = errors.errors;
    },
    cleanSaleErrors(state, errors) {
        state.Sale.errors = {};
    },

    changeProductsSaleValues(state, data) {
        state.Sale.data.ProductsSale[data.index][data.key] = data.value;
    },
    changeSaleValues(state, data) {
        state.Sale.data[data.key] = data.value;
    }

}

export default {
    state,
    actions,
    mutations,
}