import http from '../../utils/axios/admin';
let state = {
    ColorsList: {
        data: [],
        links: {},
        meta: {}
    },
    Color: {
        data: {},
        errors: {}
    },

}


let actions = {
    fetchColorsList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/colors', { params: filter }).then((response) => {
                    commit('setColorsList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

    fetchColor({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.get(' https://pode.ly/api/admin/colors/' + id).then((response) => {
                    commit('setColor', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    storeColor({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/colors', data).then((response) => {
                    commit('setColor', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setColorErrors', error.response.data);
                    }
                    reject();
                });
        });
    },
    updateColor({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/colors/' + data.get("id"), data).then((response) => {
                    commit('setColor', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setColorErrors', error.response.data);
                    }
                    reject();
                });
        });
    },

    deleteColor({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.delete(' https://pode.ly/api/admin/colors/' + id).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

}


let mutations = {
    setColorsList(state, data) {
        state.ColorsList = data;
    },
    setColor(state, data) {
        state.Color.data = data.data;
    },
    setColorErrors(state, errors) {
        state.Color.errors = errors.errors;
    },
    cleanColorErrors(state, errors) {
        state.Color.errors = {};
    },
}

export default {
    state,
    actions,
    mutations,
}