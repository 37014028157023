import http from '../../utils/axios/admin';
let state = {
    CountriesList: {
        data: [],
        links: {},
        meta: {}
    },
    Country: {
        data: {},
        errors: {}
    },

}


let actions = {
    fetchCountriesList({ commit }, filter = {}) {
        return new Promise((resolve, reject) => {
            http.get('https://pode.ly/api/admin/countries', { params: filter }).then((response) => {
                    commit('setCountriesList', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

    fetchCountry({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.get(' https://pode.ly/api/admin/countries/' + id).then((response) => {
                    commit('setCountry', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },
    storeCountry({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/countries', data).then((response) => {
                    commit('setCountry', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setCountryErrors', error.response.data);
                    }
                    reject();
                });
        });
    },
    updateCountry({ commit }, data) {
        return new Promise((resolve, reject) => {
            http.post(' https://pode.ly/api/admin/countries/' + data.get("id"), data).then((response) => {
                    commit('setCountry', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        commit('setCountryErrors', error.response.data);
                    }
                    reject();
                });
        });
    },

    deleteCountry({ commit }, id) {
        return new Promise((resolve, reject) => {
            http.delete(' https://pode.ly/api/admin/countries/' + id).then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject();
                });
        });
    },

}


let mutations = {
    setCountriesList(state, data) {
        state.CountriesList = data;
    },
    setCountry(state, data) {
        state.Country.data = data.data;
    },
    setCountryErrors(state, errors) {
        state.Country.errors = errors.errors;
    },
    cleanCountryErrors(state, errors) {
        state.Country.errors = {};
    },
}

export default {
    state,
    actions,
    mutations,
}